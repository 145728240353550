<template>
    <div style="height: 100%; width: 100%; background-color: #ffffff;">
        <router-view :class="$store.getters.currentPageVersion === 'Horizontal'?'h-child-box':'v-child-box'" :key="$route.name">
        </router-view>
    </div>
</template>

<script>
    import Horizontal from '@/components/Strategy/Index/Horizontal/Index.vue'
    import Vertical from '@/components/Strategy/Index/Vertical/Index.vue'


    export default {
        components: {
            Horizontal,
            Vertical
        },
        data() {
            return {
              
            }
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .v-child-box,
    .h-child-box {
        height: 100%;
        padding: 12px;
    }
    .h-index-box,
    .v-index-box {
        height: 100%;
        width: 100%;
    }
</style>
